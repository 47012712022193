<script setup lang="ts">
defineProps({
    colors: {
        type: Array as PropType<string[]>,
        default: () => ["#93A4BC", "#5A6273"]
    }
});

const randomId = computed(() => Math.random().toString(36).substr(2, 9));
</script>

<template>
    <svg
        width="33"
        height="32"
        viewBox="0 0 33 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g :clip-path="'url(#clip0_308_530' + randomId + ')'">
            <path
                d="M28.6617 20.0514C28.6617 19.9272 28.6125 19.808 28.5248 19.7201L27.5692 18.7645C27.2276 18.4239 26.765 18.2326 26.2826 18.2326C25.8002 18.2326 25.3376 18.4239 24.996 18.7645L19.2642 24.4964C18.9235 24.8379 18.7322 25.3006 18.7322 25.7829C18.7322 26.2653 18.9235 26.728 19.2642 27.0695L20.2198 28.0251C20.3078 28.1127 20.4269 28.1619 20.551 28.1619C20.6752 28.1619 20.7943 28.1127 20.8823 28.0251L28.5248 20.3826C28.6125 20.2947 28.6617 20.1756 28.6617 20.0514Z"
                :fill="'url(#paint0_linear_308_530' + randomId + ')'"
            />
            <path
                d="M26.2823 23.9507L24.4504 25.7826L27.4373 28.772C27.5924 28.3519 27.8367 27.9704 28.1534 27.6537C28.4701 27.3371 28.8516 27.0928 29.2717 26.9376L26.2823 23.9507Z"
                :fill="'url(#paint1_linear_308_530' + randomId + ')'"
            />
            <path
                d="M31.8692 28.3182C31.5675 28.017 31.1833 27.8118 30.7651 27.7289C30.3469 27.6459 29.9135 27.6887 29.5196 27.852C29.1258 28.0153 28.7892 28.2917 28.5524 28.6462C28.3155 29.0007 28.1891 29.4175 28.1891 29.8439C28.1891 30.2702 28.3155 30.687 28.5524 31.0415C28.7892 31.3961 29.1258 31.6725 29.5196 31.8357C29.9135 31.999 30.3469 32.0419 30.7651 31.9589C31.1833 31.8759 31.5675 31.6708 31.8692 31.3695C32.2732 30.9646 32.5001 30.4159 32.5001 29.8439C32.5001 29.2718 32.2732 28.7232 31.8692 28.3182Z"
                :fill="'url(#paint2_linear_308_530' + randomId + ')'"
            />
            <path
                d="M8.00354 18.7645C7.66201 18.4239 7.19933 18.2326 6.71697 18.2326C6.23461 18.2326 5.77194 18.4239 5.43041 18.7645L4.47479 19.7201C4.38716 19.8081 4.33796 19.9272 4.33796 20.0514C4.33796 20.1755 4.38716 20.2946 4.47479 20.3826L12.1173 28.0251C12.2053 28.1127 12.3244 28.1619 12.4485 28.1619C12.5727 28.1619 12.6918 28.1127 12.7798 28.0251L13.7354 27.0695C14.076 26.728 14.2673 26.2653 14.2673 25.7829C14.2673 25.3006 14.076 24.8379 13.7354 24.4964L8.00354 18.7645Z"
                :fill="'url(#paint3_linear_308_530' + randomId + ')'"
            />
            <path
                d="M3.72791 26.9376C4.148 27.0928 4.5295 27.3371 4.84616 27.6537C5.16283 27.9704 5.40714 28.3519 5.56229 28.772L8.55104 25.7826L6.71729 23.9507L3.72791 26.9376Z"
                :fill="'url(#paint4_linear_308_530' + randomId + ')'"
            />
            <path
                d="M1.13041 28.3182C0.828691 28.62 0.623221 29.0044 0.539983 29.4229C0.456745 29.8414 0.499478 30.2752 0.662777 30.6695C0.826077 31.0637 1.10261 31.4006 1.45741 31.6377C1.8122 31.8748 2.22933 32.0013 2.65604 32.0013C3.08274 32.0013 3.49987 31.8748 3.85467 31.6377C4.20946 31.4006 4.48599 31.0637 4.64929 30.6695C4.81259 30.2752 4.85533 29.8414 4.77209 29.4229C4.68885 29.0044 4.48338 28.62 4.18166 28.3182C3.77656 27.9145 3.22796 27.6878 2.65604 27.6878C2.08411 27.6878 1.53551 27.9145 1.13041 28.3182Z"
                :fill="'url(#paint5_linear_308_530' + randomId + ')'"
            />
            <path
                d="M1.94171 7.72517C1.96258 7.82903 2.01785 7.92282 2.09858 7.99142L11.2905 15.7689L13.2686 13.4314L10.1873 10.3527C10.1413 10.3098 10.1043 10.258 10.0787 10.2005C10.0531 10.143 10.0393 10.0809 10.0382 10.018C10.0371 9.95505 10.0487 9.89254 10.0723 9.83417C10.0958 9.7758 10.1309 9.72278 10.1754 9.67827C10.2199 9.63375 10.273 9.59867 10.3313 9.57509C10.3897 9.55151 10.4522 9.53994 10.5152 9.54105C10.5781 9.54216 10.6402 9.55593 10.6977 9.58155C10.7552 9.60717 10.8069 9.64411 10.8498 9.69017L13.8748 12.7133L15.8848 10.3383L8.49108 1.59892C8.42249 1.51818 8.32869 1.46292 8.22483 1.44204L1.06233 0.00954035C0.986397 -0.00598041 0.907799 -0.00243049 0.833573 0.0198723C0.759346 0.0421752 0.691809 0.0825349 0.637005 0.137339C0.582201 0.192143 0.541841 0.259681 0.519538 0.333907C0.497235 0.408133 0.493685 0.486731 0.509206 0.562665L1.94171 7.72517Z"
                :fill="'url(#paint6_linear_308_530' + randomId + ')'"
            />
            <path
                d="M21.7985 20.6364L23.3154 19.1189L21.5985 17.0901L19.786 18.6239L21.7985 20.6364Z"
                :fill="'url(#paint7_linear_308_530' + randomId + ')'"
            />
            <path
                d="M21.136 21.2989L19.0685 19.2314L17.2254 20.7907L19.6192 22.8157L21.136 21.2989Z"
                :fill="'url(#paint8_linear_308_530' + randomId + ')'"
            />
            <path
                d="M11.201 20.6364L22.1473 9.68762C22.2361 9.60482 22.3537 9.55974 22.4751 9.56189C22.5966 9.56403 22.7124 9.61322 22.7983 9.69911C22.8842 9.78499 22.9334 9.90086 22.9355 10.0223C22.9377 10.1437 22.8926 10.2613 22.8098 10.3501L11.8635 21.2989L13.3804 22.8157L30.901 7.99137C30.9818 7.92278 31.037 7.82898 31.0579 7.72512L32.4904 0.56262C32.5059 0.486686 32.5024 0.408088 32.4801 0.333862C32.4578 0.259635 32.4174 0.192098 32.3626 0.137294C32.3078 0.0824898 32.2403 0.0421301 32.166 0.0198272C32.0918 -0.00247559 32.0132 -0.00602551 31.9373 0.00949526L24.7748 1.442C24.6709 1.46287 24.5771 1.51814 24.5085 1.59887L9.68729 19.1195L11.201 20.6364Z"
                :fill="'url(#paint9_linear_308_530' + randomId + ')'"
            />
        </g>
        <defs>
            <linearGradient
                :id="'paint0_linear_308_530' + randomId"
                x1="16.4993"
                y1="0"
                x2="16.4993"
                y2="32.0013"
                gradientUnits="userSpaceOnUse"
            >
                <stop :stop-color="colors[0]" />
                <stop offset="1" :stop-color="colors[1]" />
            </linearGradient>
            <linearGradient
                :id="'paint1_linear_308_530' + randomId"
                x1="16.4993"
                y1="0"
                x2="16.4993"
                y2="32.0013"
                gradientUnits="userSpaceOnUse"
            >
                <stop :stop-color="colors[0]" />
                <stop offset="1" :stop-color="colors[1]" />
            </linearGradient>
            <linearGradient
                :id="'paint2_linear_308_530' + randomId"
                x1="16.4993"
                y1="0"
                x2="16.4993"
                y2="32.0013"
                gradientUnits="userSpaceOnUse"
            >
                <stop :stop-color="colors[0]" />
                <stop offset="1" :stop-color="colors[1]" />
            </linearGradient>
            <linearGradient
                :id="'paint3_linear_308_530' + randomId"
                x1="16.4993"
                y1="0"
                x2="16.4993"
                y2="32.0013"
                gradientUnits="userSpaceOnUse"
            >
                <stop :stop-color="colors[0]" />
                <stop offset="1" :stop-color="colors[1]" />
            </linearGradient>
            <linearGradient
                :id="'paint4_linear_308_530' + randomId"
                x1="16.4993"
                y1="0"
                x2="16.4993"
                y2="32.0013"
                gradientUnits="userSpaceOnUse"
            >
                <stop :stop-color="colors[0]" />
                <stop offset="1" :stop-color="colors[1]" />
            </linearGradient>
            <linearGradient
                :id="'paint5_linear_308_530' + randomId"
                x1="16.4993"
                y1="0"
                x2="16.4993"
                y2="32.0013"
                gradientUnits="userSpaceOnUse"
            >
                <stop :stop-color="colors[0]" />
                <stop offset="1" :stop-color="colors[1]" />
            </linearGradient>
            <linearGradient
                :id="'paint6_linear_308_530' + randomId"
                x1="16.4993"
                y1="0"
                x2="16.4993"
                y2="32.0013"
                gradientUnits="userSpaceOnUse"
            >
                <stop :stop-color="colors[0]" />
                <stop offset="1" :stop-color="colors[1]" />
            </linearGradient>
            <linearGradient
                :id="'paint7_linear_308_530' + randomId"
                x1="16.4993"
                y1="0"
                x2="16.4993"
                y2="32.0013"
                gradientUnits="userSpaceOnUse"
            >
                <stop :stop-color="colors[0]" />
                <stop offset="1" :stop-color="colors[1]" />
            </linearGradient>
            <linearGradient
                :id="'paint8_linear_308_530' + randomId"
                x1="16.4993"
                y1="0"
                x2="16.4993"
                y2="32.0013"
                gradientUnits="userSpaceOnUse"
            >
                <stop :stop-color="colors[0]" />
                <stop offset="1" :stop-color="colors[1]" />
            </linearGradient>
            <linearGradient
                :id="'paint9_linear_308_530' + randomId"
                x1="16.4993"
                y1="0"
                x2="16.4993"
                y2="32.0013"
                gradientUnits="userSpaceOnUse"
            >
                <stop :stop-color="colors[0]" />
                <stop offset="1" :stop-color="colors[1]" />
            </linearGradient>
            <clipPath :id="'clip0_308_530' + randomId">
                <rect
                    width="32"
                    height="32"
                    fill="white"
                    transform="translate(0.5)"
                />
            </clipPath>
        </defs>
    </svg>
</template>
